.result-container {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .result-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .fetch-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fetch-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .retry-button {
    background-color: red;
  }
  
  .thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .thumbnail {
    flex: 1 1 100px;
    max-width: 100px;
    position: relative;
    cursor: pointer;
  }
  
  .thumbnail-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .image-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  
  .image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .full-size-image {
    width: 100%;
    height: auto;
  }
  
  .close-modal-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-modal-button:hover {
    background-color: #0056b3;
  }
  