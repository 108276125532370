/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  margin: 20px;
  flex-grow: 1;
}

.logo {
  margin-right: 20px;
  width: 72px; /* Adjusted to fit better on smaller screens */
  height: 35px; /* Adjusted to fit better on smaller screens */
  vertical-align: middle;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.search-container {
  flex-grow: 1;
}

.bar {
  max-width: 500px; /* Set a maximum width for the search bar */
  width: 100%;
  border-radius: 30px;
  border: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: box-shadow 0.3s ease;
  margin: 10px 0; /* Added margin for spacing on smaller screens */
  position: relative;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.bar:hover, .bar:focus-within {
  box-shadow: 1px 1px 8px 1px #dcdcdc;
  outline: none;
}

.searchbar {
  height: 45px;
  width: 440px;
  border: none;
  flex-grow: 1;
  font-size: 16px;
  outline: none;
  padding-left: 10px;
  border-radius: 30px;
  background-color: transparent;
}

.searchbar:focus {
  outline: none;
  box-shadow: none;
}

.search-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.search-icon {
  height: 20px;
}

.voice {
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.buttons {
  display: none;
}

.sideContent {
  padding: 10px;
  border-right: 1px solid #ddd;
}

.resultsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px; /* Added padding for smaller screens */
}

.resultsContainer {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
}

.pagingContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: flex-start;
  }

  .logo {
    margin: 0 0 10px 0; /* Adjust margin for smaller screens */
  }

  .bar {
    margin: 0; /* Remove additional margin on smaller screens */
  }
}
/* App.css */

.logo {
  margin-right: 20px;
  width: auto;
  height: 50px; /* Adjust height as needed */
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.switch-button {
  margin-left: auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.switch-button:hover {
  background-color: #0056b3;
}
