/* SearchPage.css */

.search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  margin-top: 50px;
  padding: 0 20px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  box-sizing: border-box;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.header-link {
  text-decoration: none;
  color: #5f6368;
  font-size: 14px;
}

.sign-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.grid-icon {
  width: 20px;
  height: 20px;
}

.logo-container {
  margin: 40px 0;
}

.logo {
  width: 272px;
  height: 92px;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-form {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  border: 1px solid #dfe1e5;
  border-radius: 24px;
  padding: 10px 20px;
  box-shadow: 0px 1px 6px rgba(32, 33, 36, 0.28);
  background-color: white;
}

.search-bar {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.voice-search-button {
  background: none;
  border: none;
  cursor: pointer;
}

.voice-icon {
  width: 24px;
  height: 24px;
}

.search-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.search-button {
  background-color: #f8f9fa;
  border: 1px solid #dadce0;
  color: #3c4043;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #f1f3f4;
  box-shadow: 0px 1px 3px rgba(60, 64, 67, 0.3);
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .header {
    justify-content: center;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .logo-container {
    margin: 20px 0;
  }

  .search-form {
    padding: 10px;
  }

  .search-bar {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .search-page {
    margin-top: 20px;
  }

  .header {
    padding: 5px 0;
  }

  .nav-links {
    gap: 5px;
  }

  .logo {
    width: 200px;
    height: 68px;
  }

  .search-form {
    padding: 8px;
    border-radius: 20px;
  }

  .search-bar {
    font-size: 12px;
  }

  .voice-icon {
    width: 20px;
    height: 20px;
  }

  .search-buttons {
    margin-top: 10px;
  }

  .search-button {
    padding: 8px 16px;
  }
}